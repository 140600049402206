
import {
  IonPage,
  IonHeader,
  IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { getAboutUs } from "@/service/member";
import Header from "@/components/header.vue";

export default {
  name: "UseClause",
  components: { Header, IonHeader, IonContent, IonPage },
  setup() {
    const aboutusobj: any = ref([]);

    const getTeamSettingDetail = async () => {
      const result = await getAboutUs({
        enCode: "usehelpdesc",
      });
      aboutusobj.value = result;
      console.log(aboutusobj.value.settext);
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      getTeamSettingDetail();
    });

    return { aboutusobj };
  },
};
